<template>
  <div class="kg-fullform-wrapper">
    <div class="container">
      <div class="kg-fullform-wrapper__content">
        <a-row :gutter="100">
          <a-col class="hidden sm:hidden md:hidden lg:block" :md="12" :lg="12">
            <MoleculesFullformBanner />
          </a-col>

          <a-col :xs="24" :sm="24" :md="12" :lg="12">
            <close-outlined class="close-button" @click="$router.push('/')" />
            <slot />
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import './styles.scss';
</style>
