<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    alt?: string
    clickable?: boolean
  }>(),
  {
    alt: 'kognisi-logo',
    clickable: false
  }
)

const { alt, clickable } = toRefs(props)

const { push } = useRouter()

const onLogoClick = () => {
  if (!clickable) {
    return
  }
  push('/')
}
</script>

<template>
  <img
    :class="`kg-logo ${clickable ? 'clickable' : ''}`"
    loading="lazy"
    src="~/assets/images/logo-kognisi.svg"
    :alt="alt"
    @click="onLogoClick"
  />
</template>

<style lang="scss">
@import './styles.scss';
</style>
